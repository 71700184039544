import React from 'react';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
import Preflat from '../Components/Preflats';
const Tharun = () => {
  return <div>
      <Title titles="Tharun Enclave" />
      <br />
      <Preflat imgsrc="./images/Tharun.webp" btnurl="/Tharun" near1="Near San Acadamey School" near2="Near Ags Colony Bus Stand" a={true} />
      <br />
<Title titles="Configuration" />
<br />
<table class="bg-white w-full container mx-auto  auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
                <tr class="">
                    <th class="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                    <th class="bg-crimson-700  text-slate-50 p-2 w-auto">Type</th>
                    <th class="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F1/S1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">916</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F2/S2</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">877</td>
                </tr>
               
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F3/S3</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">964</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F4/S4</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">974</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">T1</td>
                    <td class="p-2">3BHK</td>
                    <td class="p-2  ">1113</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">T2</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">964</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">T3</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">974</td>
                </tr>
               
            </tbody>
        </table>
        <br />
<Faddmap flname="Tharun Enclave" add1="Plot No 2 & 3 ," add2="Ags Colony 5th Main Road, Vinayagapuram" add3="Velachery" add4="Chennai-42"   mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9071030484984!2d80.20796807587975!3d12.977793214740908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d9f26e44721%3A0x1e9b732f121f0158!2sGK%20Tharun%20Enclave!5e0!3m2!1sen!2sin!4v1711537042837!5m2!1sen!2sin" />
<br />
  </div>


};

export default Tharun;
