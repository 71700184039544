import React from 'react';
import ContactUs from '../Components/Form';
import { HomeIcon } from "@heroicons/react/solid";
import { PhoneIcon } from "@heroicons/react/solid";
import { MailOpenIcon } from "@heroicons/react/solid";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter} from "react-icons/fa";
const Contact = () => {
  return <div>
           <div class="container mx-auto">
            <div class="">
                <h2 class="text-center text-2xl lg:text-3xl font-bold">
                    Contact Us
                </h2>
                <p class="lg:w-1/2 text-center text-xl mx-auto mt-5">
  For Enquries and Quotation Please Contact Us Using the form below!😀</p>
            </div>
            <div className='flex  items-center content-center'>
<img class="hidden lg:flex w-1/2 " src='/images/contactus.webp'></img>
            <div class="mt-7 p-2 lg:w-1/2  mx-auto">
                <ContactUs />
                </div>
                </div>

            </div>
            <div className=" rounded-2xl container mx-auto  ">
  <h1 className="text-4xl  font-bold text-center ">
    Contact
  </h1>
  <div className=" flex flex-wrap">

    <div className="w-full lg:w-1/2 border-4 border-gray-800 rounded-2xl">
      <div className="text-2xl">
        <h1 className="text-3xl   text-center font-extrabold  border-gray-400 text-gray-800"> Address </h1>
      </div>
 
      <div className=" ">
        
           
              <p className="flex text-xl items-center justify-start px-10 lg:px-40 mt-2 mb-2 mx-2 ">
                <HomeIcon className="h-8 w-8 " />
                <p className="lg:text-xl text-left mx-2  "> No.54 B , <br /> 1st Cross Street, <br /> Adambakkam,
                  Chennai-600088</p>
              </p>
              <p className="flex text-xl items-center px-10 lg:px-40   mb-4 mx-2 ">
            
                <MailOpenIcon className="w-7 h-7" /><a className="" href="mailto:info@gkpromoters.com ">
                   <span className="lg:text-xl text-left mx-2  "> info@gkpromoters.com</span></a>
                
              </p>
              <p className="flex text-xl items-center px-10 lg:px-40 mx-2 mb-3   ">
             <PhoneIcon className="h-8 w-8"/> 
             <a href="tel:+918939764764 ">  <span className="lg:text-xl text-left mx-2  "> +91-8939764764 </span> </a>
           <a href="tel:+919791028585 ">  <span className="lg:text-xl text-left mx-2  ">| +91-9791028585</span> </a>
              </p>
              <p className="flex text- items-center px-10 lg:px-40 mt mb-4 mx-2md:justify-start  ">
                <a href="https://www.facebook.com/GK-Promoters-106735478509961/ " className="mr-6 text-blue-600 hover:text-blue-800">
                  
                  <FaFacebookF className="h-10 w-8  " />
                </a>
                <a href="https://twitter.com/promoters_gk " className="mr-6 text-blue-400 hover:text-blue-600 ">
                   
                <FaTwitter className="h-11 w-11" />
                </a>
                <a href="https://www.instagram.com/gk.promoters22/ " className="mr-6 text-purple-500 hover:text-purple-700  ">
           
                   <FaInstagram className="h-11 w-11" />
                </a>
              </p>
            </div>
            <div className="p-5 mt-6  text-left w-full md:w-1/4 ">
            </div>
      
     </div>
    <div className="w-full lg:w-1/2 bg-blue-300  rounded-2xl">
      <div ><iframe className="rounded-2xl" width="100%" height={350} frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d408.6515813353165!2d80.20699693635189!3d12.986497087761514!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda6021ce17413e01!2sgk%20promoters!5e0!3m2!1sen!2sin!4v1645003216319!5m2!1sen!2sin">
        </iframe></div>
    </div>
  </div>
</div>

  </div>
};

export default Contact;
