import React from 'react';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
// import Preflat from '../Components/Preflats';
import Flat from '../Components/flat';
const SriSaiRiya = () => {
  return <div>
      <Title titles="Sai Shree" />
      <br />
     <Flat imgsrc="./images/Sai Shree.webp" btnurl="/SaiShree" near1="Near Vyasa Vidhyalaya School" near2="Near Puzhuthivakkam Bus Stand" a={true} />
      <br />
<Title titles="Configuration" />
<br />
<table class="bg-white w-full container mx-auto  auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
                <tr class="">
                    <th class="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                    <th class="bg-crimson-700  text-slate-50 p-2 w-auto">Type</th>
                    <th class="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">G1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">977</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F1/S1</td>
                    <td class="p-2">3BHK</td>
                    <td class="p-2  ">1207</td>
                </tr>
               
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F2/S2</td>
                    <td class="p-2">3BHK</td>
                    <td class="p-2  ">1380</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">T1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">1018</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">T2</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">942</td>
                </tr>
         
            </tbody>
        </table>
        <br />
<Faddmap flname="Sai Shree" add1="Plot No 1B & 2B ," add2="Elumalai Nagar , Annai Tersa Nagar 9th street" add3="Puzuthivakkam" add4="Chennai-91"   mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d846.1829969458854!2d80.20223902836955!3d12.96956659600167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d00780ef0f1%3A0x62c5bcff7797ff1c!2sGK%20Sri%20Sai%20Riya%20Enclave!5e0!3m2!1sen!2sin!4v1716530962069!5m2!1sen!2sin" />
<br />
  </div>


};

export default SriSaiRiya;
