import React from 'react';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import About from '../Pages/about';
import Contact from '../Pages/Contact';
import Gallery from '../Pages/Gallery';
import CurrentProject from '../Pages/CurrentProject';
import CompletedProjects from '../Pages/CompletedProjects';
import Akrthi from '../Pages/Akrthi';
import Thiara from '../Pages/Thiara';
import SaiShree from '../Pages/SaiShree';
import Srisaithiara from '../Pages/Srisaithiara';
import Srisairamya from '../Pages/Srisairamya';
import Tharun from '../Pages/Tharun';
import Srisaitharun from '../Pages/Srisaitharun';
import Srisaikubera from '../Pages/Srisaikubera';
import Srisaibaba from '../Pages/Srisaibaba';
import Srisaishanmuga from '../Pages/Srisaishanmuga';
import Srisaiaditiya from '../Pages/Srisaiaditiya';
import Thanshikaa from '../Pages/Thanshikaa';
import Rakeshpranav from '../Pages/Rakeshpranav';
import Specification from '../Pages/Specification';
import { Footer } from "../Components/footer";
import Navbar from "../Components/Nav";
import Home from '../Pages/home';
import ScrollToTop from './Top';
import Landing from '../Pages/Landing';
import Error from '../Pages/Error';
import Disclaimer from '../Pages/Disclaimer';
import FloatingWhatsApp from 'react-floating-whatsapp';
import Privacy from '../Pages/Privacy';
import Sidebar from './Sidebar';
import Navbar1 from './Navbar';
import StickyButtons from './Mobcon';
import { FaWhatsapp } from 'react-icons/fa';  // Assuming you're using react-icons for icons
import LgscrollToTop from './Lgtop';
import Timelinearray from './Timelinearray';
import Aboutus from './Aboutus';
import AboutUsTabs from './AboutUsTabs';
const Rout = () => {
  return <div>
   <Router>
    
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/CurrentProjects' element={<CurrentProject />} />
        <Route path='/CompletedProjects' element={<CompletedProjects />} />
        <Route path='/Akrthi' element={<Akrthi />} />
        <Route path='/Thiara' element={<Thiara />} />
        <Route path='/SaiShree' element={<SaiShree />} />
        <Route path='/Specification' element={<Specification />} />
        <Route path='/Srisaithiara' element={<Srisaithiara />} />
        <Route path='/Srisairamya' element={<Srisairamya />} />
        <Route path='/Srisaitharun' element={<Srisaitharun />} />
        <Route path='/Srisaikubera' element={<Srisaikubera />} />
        <Route path='/Srisairamya' element={<Srisaikubera />} />
        <Route path='/Tharun' element={<Tharun />} />
        <Route path='/Srisaibaba' element={<Srisaibaba />} />
        <Route path='/Srisaishanmuga' element={<Srisaishanmuga />} />
        <Route path='/Srisaiaditiya' element={<Srisaiaditiya />} />
        <Route path='/Thanshikaa' element={<Thanshikaa />} />
        <Route path='/Rakeshpranav' element={<Rakeshpranav />} />
        <Route path='/Specification' element={<Specification />} />
        <Route path='/Disclaimer' element={<Disclaimer />} />
        <Route path='/Privacy' element={<Privacy/>} />
        <Route path ="*"  element={<Error />} />
        <Route path ="Landing" element={<Landing />} />

</Routes>

<Footer />
<FloatingWhatsApp
        phoneNumber="+918939764764"
        accountName="GK Promters"
        avatar="./images/Logo-removebg-preview.png"
        position="top"
        className='hidden lg:flex'
      />
   
  
      </Router>
      {/* <ScrollToTop />  */}
      <LgscrollToTop />
  </div>;
};

export default Rout;
