import React, { useRef } from "react";
import emailjs, { sendForm } from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


import Swal from "sweetalert2";
import { useSize } from "react-use";
const SERVICE_ID = "service_9rntsc2";
const TEMPLATE_ID = "template_prgmijj";
const USER_ID = "user_0kgEk9S63fdQtimcYVscY";

const ContactUs = ({ setShowModal }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9rntsc2",
        "template_prgmijj",
        form.current,
        "user_0kgEk9S63fdQtimcYVscY"
      )
      .then(
        (result) => {
          console.log(result.text);        
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
          });
        e.target.reset();
        document.getElementById("phoneInput").value="";
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Ooops, something went wrong",
            text: error.text,
          });
        }
      );
    form.current.value = null;
    setShowModal(false);
  };

  return (
<form id="form" className="mx-auto border-2 p-2 border-red-500 rounded-lg" ref={form} onSubmit={sendEmail}>
  <div className="mb-4 flex flex-wrap items-center">
    <label className="text-gray-800 w-1/5 font-bold text-xl mr-2">Name</label>
    <input
      placeholder="Your name"
      tabIndex="1"
      required
      autoFocus
      className="w-full  focus:outline-none border-b-2 border-gray-400 p-2 rounded"
      type="text"
      name="from_name"
   
    />
  </div>

  <div className="mb-4 flex flex-wrap items-center">
    <label className="text-gray-800 w-1/5 font-bold text-xl mr-2">Number</label>
    <PhoneInput
      country={'in'} 
      value=""
      inputProps={{
        name: "phone",
        required: true,
        autoFocus: false,
        placeholder: '919876543210',
        id: 'phoneInput'
       
      }}
      containerStyle={{
        border: "2px solid #ddd",
        borderRadius: "0.375rem",
        
      }}
      inputStyle={{
        background: "white",
        width: "100%",
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
        padding: "0.5rem",
        border: "none",
        borderRadius: "0.375rem",
        padding: "0.5rem",
       paddingLeft : ""
      }}
    />
  </div>

  <div className="mb-4 flex flex-wrap items-center">
    <label className="text-gray-800 w-1/5 font-bold text-xl mr-2">Email</label>
    <input
      placeholder="Email"
      tabIndex="1"
      required
      
      className="w-full p-1 focus:outline-none border-b-2 border-gray-400 px-2 py-2 rounded"
      type="email"
      name="email"
   
    />
  </div>

  <div className="mb-4 flex flex-wrap items-center">
    <label className="text-gray-800 w-1/5 font-bold text-xl mr-2">Message</label>
    <textarea
      placeholder="Type your message here...."
      tabIndex="5"
      required
      className="w-full p-1 focus:outline-none border-b-2 border-gray-400 px-2 py-2 rounded"
      name="message"
      
    />
  </div>

  <input
    data-submit="...Sending"
    className=" px-6 py-2 mb-2 w-auto block mt-3  text-slate-50 bg-crimson-600 hover:bg-crimson-700 rounded-lg"
    type="submit"
    value="Send"
  />

</form>

  );
};
export default ContactUs;
