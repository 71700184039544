import React from 'react';
import Modal from './popup';
import {Link} from "react-router-dom";
import { HomeIcon, PhoneIcon, MailOpenIcon } from "@heroicons/react/solid";
import { FaFacebookF, FaInstagram, FaTwitter, FaPhoneAlt, FaWhatsapp, FaLevelUpAlt } from "react-icons/fa";
import ScrollToTop from './Top';

export const Footer = () => {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return(
    <div className="flex flex-col h-auto sm:block font-navfont font-semibold bg-slate-100">
     <div className=" text-white ">
        <div className="flex flex-wrap ">
        <div className='w-1/4'></div>
          <div className="p-5 mt-2 text-left  w-full md:w-1/4 ">
            <ul className="text-xl  ">
              <li className="mt-1 "><Link to="/ ">Home</Link></li>
              <li className="mt-1 "><Link to="/About">About</Link></li>
              <li className="mt-1 "><Link to="/CurrentProjects">Current Projects</Link></li>
              <li className="mt-1 "><Link to="/CompletedProjects ">Completed Projects</Link></li>
              <li className="mt-1 "><Link to="/Contact">Contact</Link></li>
              <li className="mt-1 "><Link to="/Gallery">Gallery</Link></li>
              <li className="mt-1 "><a href="https://www.ysventures.in ">Interiors</a></li>
              <li className="mt-1"><Link  to="/Disclaimer ">Disclaimer</Link></li>
              <li className="mt-1 "><Link  to="/Privacy ">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className=" text-center  lg:text-right w-full md:w-1/4 ">
            <p className="flex text-xl items-center justify-start mb-4 mt-2 mx-2 ">
              <HomeIcon className="h-8 w-8 " />
              <span className="lg:text-xl text-left mx-2  "> No.54 B, <br /> L.H. Nagar, 1st Cross Street, <br /> Adambakkam,
                Chennai-600088</span>
            </p>
            <p className="flex text-xl items-center justify-start mb-4 mx-2 ">
              <MailOpenIcon className="w-7 h-7" /><a className="" href="mailto:info@gkpromoters.com ">
                 <span className="lg:text-xl text-left mx-2  "> info@gkpromoters.com</span></a>
            </p>
            <p className="flex text-xl items-center justify-start mb-4 mx-2  ">
              <PhoneIcon className="h-8 w-8"/> 
              <a href="tel:+918939764764 ">  <span className="lg:text-xl text-left mx-2  ">+91-8939764764 </span> </a>
              <a href="tel:+919791028585 ">  <span className="lg:text-xl text-left mx-2  ">| +91-9791082885</span> </a>
            </p>
            <p className="flex text- items-center justify-center md:justify-start mb-4 ">
              <a href="https://www.facebook.com/GK-Promoters-106735478509961/ " className="mr-6 text-blue-600 ">
                <FaFacebookF className="h-10 w-8" />
              </a>
              <a href="https://twitter.com/promoters_gk " className="mr-6 text-blue-400 ">
                <FaTwitter className="h-11 w-11" />
              </a>
              <a href="https://www.instagram.com/gk.promoters22/ " className="mr-6 text-purple-500 ">
                 <FaInstagram className="h-11 w-11" />
              </a>
            </p>
          </div>
        </div>
        <div className=" border-t text-center mb-12 lg:mb-0 border-white">
          <div className=" text-sm lg:text-xl text-center ">
            Copyright ©{new Date().getFullYear()} . All rights reserved by GK PROMOTERS
          </div>
        </div>

      </div>      
      <div className="block sm:hidden fixed bottom-0 left-0 z-50 bg-crimson-600 w-full">
  <ul className="flex m-0 p-0">
    <li className="flex-1 text-white text-center border-r border-white">
      <a href="tel:+918939764764" className="flex justify-center items-center h-12 text-slate-50">
        <FaPhoneAlt className="mr-2" /> Call Now 
      </a>
    </li>

    <li className="flex-1 text-white text-center border-r border-white">
      <div className="flex justify-center items-center h-12 text-slate-900">
        <Modal btnclass="py-auto text-slate-50  text-lg  py-2 px-4 rounded-full" />
      </div>
    </li>

    <li className="flex-1 text-slate-50 text-center">
      <a href="https://wa.me/918939764764" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center h-12 text-slate-50">
        <FaWhatsapp className="mr-2" /> Chat Now
      </a>
    </li>
  </ul>
  <button onClick={scrollToTop} className="fixed bottom-14  right-6 bg-crimson-600 animate-bounce  text-slate-50 h-12 w-12 rounded-full flex items-center justify-center text-xl cursor-pointer">
    <FaLevelUpAlt />
  </button>
</div>
    </div>
  );
}
